import React from 'react'
import { LayoutWrapper } from '../components/layout/layoutWrapper'
import { BasicKnowledgeGroups } from '../components/knowledge/basicKnowledgeGroups'
import { Seo } from '../components/seo/Seo'
import { AppLocale, GenerateLocales } from '@hrk/types'
import { useNavigationItems } from '../hooks/useNavigationItems'
import { SiteMetadata, useSiteMetadata } from '../hooks/useSiteConfig'

const Wissensbereiche: React.FC = () => {
  const currentSlug = '/wissensbereiche'
  const currentLocale: AppLocale = GenerateLocales.de
  const seoTitle = 'Weiterbildung gesucht? hoch & weit hat die Antwort!'
  const seoDescription =
    'Alle Wissensbereiche & alle Weiterbildungsmöglichkeiten im Überblick. Jetzt die passende Weiterbildung finden auf hoch & weit!'

  const { collectBreadCrumbs } = useNavigationItems()
  const currentPagePath = collectBreadCrumbs({ slug: currentSlug, title: seoTitle, type: 'KnowledgeAreas' })
  // const currentPdfUrl: DownloadLink | undefined = props.pageContext.pdfUrl
  // ? {
  //     url: props.pageContext.pdfUrl,
  //     target: '_blank',
  //     title: downloadTranslations.downloadTitleHsp,
  //     downloadFilename: `Weiterbildungsangebot-${strapiTrainingOffer.id}-${currentLocale}.pdf`,
  //   }
  // : undefined

  return (
    <>
      <Seo title={seoTitle} slug={currentSlug} locale={currentLocale} description={seoDescription}></Seo>
      <LayoutWrapper
        currentLocale={currentLocale}
        currentTitle={seoTitle}
        currentSlug={currentSlug}
        currentPath={currentPagePath}
        // currentPdfLink={currentPdfUrl}
        otherLocales={[{ locale: GenerateLocales.en, slug: '/en/knowledge-areas/' }]}
      >
        <BasicKnowledgeGroups />
      </LayoutWrapper>
    </>
  )
}

export const Head = ({ location, params, data, pageContext }) => {
  const site: SiteMetadata = useSiteMetadata(pageContext.locale as AppLocale)
  return <link rel="canonical" href={`${site.metadata.siteUrl}/wissensbereiche/`} />
}

export default Wissensbereiche
